import { ComponentConfig } from 'components/src/utils/ComponentOverridesContext'
import AccountHero from 'jbfo/src/components/AccountHero/AccountHero'
import AccountIcon from 'jbfo/src/components/AccountIcon'
import BreadcrumbIcon from 'jbfo/src/components/BreadcrumbIcon'
import NotFoundBackgroundImages from 'jbfo/src/components/NotFoundPage/NotFoundBackgroundImages'
import PickupPointIcon from 'jbfo/src/components/PickupPointIcon'
import PostalAddressIcon from 'jbfo/src/components/PostalAddressIcon'
import SuccessPageBackgroundImages from 'jbfo/src/components/SuccessPage/SuccessPageBackgroundImages'
import SuccessPageButtons from 'jbfo/src/components/SuccessPage/SuccessPageButtons'

import AccountLoginPageLayout from './components/AccountLoginPage/AccountLoginPageLayout'
import AppRouter from './components/AppRouter/AppRouter'
import CartTotalsFooterPrice from './components/cart/CartTotals/CartTotalsFooterPrice'
import CouponFormNote from './components/CouponFormNote'
import Logo from './components/Logo'
import TileSlider from './components/TileSlider/TileSlider'
import ThemeOverrides from './theme/ThemeOverrides'

const componentsConfig: ComponentConfig = {
    AccountHero,
    AccountIcon,
    AppRouter,
    BreadcrumbIcon,
    Logo,
    NotFoundBackgroundImages,
    PickupPointIcon,
    PostalAddressIcon,
    SuccessPageBackgroundImages,
    SuccessPageButtons,
    CartTotalsFooterPrice,
    TileSlider,
    ThemeOverrides,
    AccountLoginPageLayout,
    CouponFormNote,
}

export default componentsConfig
